import ActionTypes from "../../constants/ActionTypes"

const INITIAL_STATE = {
  showFinished: false,
  showPlausi: false,
  showNextDays: 2,
  showWorker: -1,
  rejectType: null,
  troubleType: null,
  progressData: {},
}

function workListReducer(state = INITIAL_STATE, action) {
  const { payload } = action
  switch (action.type) {
    case ActionTypes.TERMINAL_SET_SEARCH:
      return {
        ...state,
        searchText: payload,
      }
    case ActionTypes.TERMINAL_SET_SHOW_FINISHED:
      return {
        ...state,
        showFinished: payload,
      }
    case ActionTypes.TERMINAL_SET_SHOW_NEXT_DAYS:
      return {
        ...state,
        showNextDays: payload,
      }
    case ActionTypes.TERMINAL_SET_SHOW_WORKER:
      return {
        ...state,
        showWorker: payload,
      }
    case ActionTypes.TERMINAL_SET_SHOW_PLAUSI:
      return {
        ...state,
        showPlausi: payload,
      }
    case ActionTypes.TERMINAL_SET_REJECTTYPE:
      return {
        ...state,
        rejectType: payload,
      }
    case ActionTypes.TERMINAL_SET_TROUBLETYPE:
      return {
        ...state,
        troubleType: payload,
      }
    case ActionTypes.TERMINAL_SET_PROGRESS_DATA:
      return {
        ...state,
        progressData: payload || {},
      }
    default:
      return state
  }
}

export default workListReducer
